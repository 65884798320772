









import { defineComponent, ref } from '@vue/composition-api';
import { ArrowRightSmallIcon } from '@nimiq/vue-components';
import { LocaleMessage } from 'vue-i18n';
import { RouteName, useRouter } from '@/router';
import { useI18n } from '@/lib/useI18n';
import BlueLink from './BlueLink.vue';
import CrossCloseButton from './CrossCloseButton.vue';

const STORAGE_KEY = 'announcement-box-dismissed';

export default defineComponent({
    setup() {
        const router = useRouter();
        const { $t } = useI18n();

        // text and cta must be functions for translations to work!
        let text: () => LocaleMessage = () => '';
        let cta: () => LocaleMessage = () => '';
        let action: string | (() => LocaleMessage) | (() => void) = '';
        let storageKey = ''; // Used to identify if the box has been dismissed yet

        // text = () => $t('Buy NIM & BTC with OASIS!');
        text = () => ''; // Disables AnnouncementBox
        cta = () => $t('Try it now');
        action = () => router.push({ name: RouteName.Buy });
        storageKey = 'buy-with-oasis-1';

        const wasDismissed = ref(window.localStorage.getItem(STORAGE_KEY) === storageKey);

        function close() {
            wasDismissed.value = true;
            window.localStorage.setItem(STORAGE_KEY, storageKey);
        }

        return {
            text,
            cta,
            action,
            wasDismissed,
            close,
        };
    },
    components: {
        BlueLink,
        ArrowRightSmallIcon,
        CrossCloseButton,
    },
});
