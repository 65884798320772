



























import { defineComponent, computed } from '@vue/composition-api';
import { Identicon, MenuDotsIcon } from '@nimiq/vue-components';
import { useRouter } from '@/router';

import LoginFileIcon from './icons/LoginFileIcon.vue';
import LedgerIcon from './icons/LedgerIcon.vue';
import { getBackgroundClass } from '../lib/AddressColor';
import { useAccountStore, AccountType } from '../stores/Account';
import { useAddressStore } from '../stores/Address';

export default defineComponent({
    setup(props, context) {
        const router = useRouter();
        const { activeAccountInfo } = useAccountStore();
        const { state: addressState } = useAddressStore();

        function openMenu() {
            router.push({
                name: `${router.currentRoute.name}-accounts`,
                query: { sidebar: 'true' },
            });
        }

        const backgroundColor = computed(() => !!activeAccountInfo.value
            && getBackgroundClass(activeAccountInfo.value.addresses[0]));

        const firstAddressInfo = computed(() => activeAccountInfo.value
            && addressState.addressInfos[activeAccountInfo.value.addresses[0]]);

        function goToAccount(testForMenuOpening = true) {
            if (testForMenuOpening && router.currentRoute.name === 'root') {
                openMenu();
                return;
            }
            context.emit('click');
        }

        return {
            activeAccountInfo,
            backgroundColor,
            openMenu,
            AccountType,
            firstAddressInfo,
            goToAccount,
        };
    },
    components: {
        LoginFileIcon,
        LedgerIcon,
        MenuDotsIcon,
        Identicon,
    },
});
