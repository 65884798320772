







import { defineComponent } from '@vue/composition-api';
import { RouteName } from '@/router';
import CircledQuestionMark from './icons/CircledQuestionMark.vue';

export default defineComponent({
    props: {},
    setup() {
        return {
            RouteName,
        };
    },
    components: {
        CircledQuestionMark,
    },
});
